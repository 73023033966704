import * as amplitude from 'amplitude-js';
import * as jwt_decode from 'jwt-decode';

type InitCallback = (client: amplitude.AmplitudeClient) => void;

type Mode = 'live' | 'test';

enum EventCategories {
    _1_Awareness = 'awareness',
    _2_Consideration = 'consideration',
    _3_Registration = 'registration',
    _4_Onboarding = 'onboarding',
    _5_Activation = 'activation',
    _6_Usage = 'usage',
}

interface watchedYoutubeVideoProps{
    videoId: string,
    videoTitle: string,
}

export default class SnipcartExperience {

    public amplitude: amplitude.AmplitudeClient;

    public identifier: string;

    private _initialized = false;
    private _loggedIn = false;

    private _onInitCallbacks: InitCallback[] = [];

    constructor() {
        this.amplitude = amplitude.getInstance("SnipcartExperience");
    }

    init(
        apiKey: string,
        identity?: string,
        callback?: InitCallback): void {

        let userId: string | undefined;

        if (identity) {
            try {
                const jwt = jwt_decode<{ id: number }>(identity);
                userId = jwt.id.toString();
                this._loggedIn = true;
            }
            catch{ userId = identity.toString(); }
            
            userId = userId.padStart(12, '0');
        }

        // https://help.amplitude.com/hc/en-us/articles/115001361248#settings-configuration-options
        this.amplitude.init(
            apiKey,
            userId,
            {
                apiEndpoint: API_ENDPOINT,
                includeUtm: true,
                includeReferrer: true,
                logLevel: PRODUCTION ? "WARN" : "INFO"
            },
            this.onInit(callback).bind(this))
    }

    private onInit(callback?: InitCallback): InitCallback {
        if (callback) {
            this._onInitCallbacks.push(callback)
        }
        return (client: amplitude.AmplitudeClient) => {
            this.identifier = `${client.options.deviceId}:${client.getSessionId()}`;
            this._initialized = true;
            this._onInitCallbacks.forEach(c => c(client));
        }
    }

    docsPageView(data: {}) {
        this.logPageView(
            EventCategories._2_Consideration,
            EventCategories._4_Onboarding,
            data)
    }

    websitePageView(data: {}) {
        this.logPageView(
            EventCategories._1_Awareness,
            null,
            data)
    }

    loadedDashboard(mode: Mode) {
        this.do(() => {
            this.logEvent(
                "loaded_dashboard",
                EventCategories._6_Usage,
                null,
                mode);
        });
    }

    startedYoutubeVideo(data: watchedYoutubeVideoProps){
        this.do(() => {
            this.logEvent(
                "started_watching",
                this._loggedIn ? EventCategories._4_Onboarding : EventCategories._2_Consideration,
                data
            )
        })
    }

    finishedYoutubeVideo(data: watchedYoutubeVideoProps){
        this.do(() => {
            this.logEvent(
                "finished_video",
                this._loggedIn ? EventCategories._4_Onboarding : EventCategories._2_Consideration,
                data
            )
        })
    }

    private logPageView(
        loggedOutCategory: EventCategories,
        loggedInCategory?: EventCategories,
        data?: {}) {

        this.do(() => {
            const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

            this.amplitude.identify(new amplitude.Identify()
                .setOnce("initial_landing_page", url)
            )

            this.logEvent(
                "viewed_page",
                this._loggedIn
                    ? (loggedInCategory || loggedOutCategory)
                    : loggedOutCategory,
                {
                    "page_url": url,
                    ...data
                });
        });
    }

    private do(action: () => void) {
        if (!this._initialized) {
            this._onInitCallbacks.push(action.bind(this));
        } else {
            action.bind(this)();
        }
    }

    public logEvent(
        name: string,
        category: EventCategories,
        data?: {},
        mode: Mode = 'live') {
        data = data || {};
        this.do(() => {
            this.amplitude.logEvent(
                name,
                {
                    "category": category,
                    "mode": mode,
                    ...data,
                });
        })
    }

}
